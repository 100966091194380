import React from 'react';
import './Menu.css';
import Submenu from './Submenu';

const menu = ['Mobiles & Accessories',
'Computers & Tablets',
'TV & Audio',
'Kitchen Appliances',
'Home Appliances',
'Smart Technology',
'Personal & Health Care',
'Offers'
];
// const length = menu.length;
// console.log(length);
const menuitems = menu.map((menuitem) =>
<li className="nav__menu-item">{menuitem} <Submenu /></li>
);

class Menu extends React.Component {  
    render() {
      return (
        <div className="menu-block">
        <nav className="nav">
          <ul className="nav__menu">

{menuitems}
            </ul>
        </nav>
        </div>
      )
    }
  }

  export default Menu;