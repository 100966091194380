import React from 'react';
import '../Header.css';

const Search = () => {
    return (
        <div>
        <input className="header-search-text" type="text" placeholder="Search for Products, Brands, Offers"></input>
        <button className="header-search-button" type="submit"></button>
        <svg xmlns="http://www.w3.org/2000/svg"
         className="header-search-magnif" 
         fill="none" 
         viewBox="0 0 24 24" 
         stroke="#000">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z">
        </path></svg>
        </div>
    )
}
export default Search;