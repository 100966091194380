import React, {useState} from 'react';
import SliderData from './SliderData';
import {FaAngleRight,FaAngleLeft} from 'react-icons/fa';
import './Slider.css';

function ImageSlider({ slides }) {
    
    const [current, setCurrent] = useState(0);
    const length = slides.length

    if(!Array.isArray(slides) || length <= 0) {
        return null
    }
    
    const nextSlide = () => {
        setCurrent(current === length-1 ? 0 : current+1)
    };
    const prevSlide = () => { 
        setCurrent(current=== 0 ? length-1 : current-1  )
    };
    
    return (
    <div className="slider">
      
       <FaAngleLeft className="left-arrow" onClick={prevSlide}/>
        <FaAngleRight className="right-arrow" onClick={nextSlide}/>
    
    
    {SliderData.map((slide, index) => {
        return(
            
            <div className={index === current ? 'slide active' : 'slide'} >
                {index === current && (<img src={slide.image} alt="slider project" className="image" />)}
              
            </div>
          
        )

    })}
    </div>
  )
}

export default ImageSlider;