// import logo from './logo.svg';
import './App.css';
import Header from './Components/Header';
import Menu from './Menu/Menu';
import ImageSlider from './Components/ImageSlider';
import SliderData from './Components/SliderData';
import ReactSimplyCarouselExample from './Components/ReactSimplyCarouselExample'
import Carousel from './Components/Carousel'



function App() {
  return (
    <div >
      <Header />
      <Menu />
      <ImageSlider slides={SliderData}/>
        {/* <ReactSimplyCarouselExample /> */}
    </div>
  );
}

export default App;

