// import image1 from '../Assets/01.jpg';
// import image from '/src/Assets/01.jpg'

const SliderData = [
{
   image: 'https://img.poorvika.com/cdn-cgi/image/width=1900,height=600,quality=75/HomePage-Banner/Main-Banner/Apple/July/Apple-ipad-Now-Available-At-poorvika-01.jpg'
},
{
    image: 'https://img.poorvika.com/cdn-cgi/image/width=1900,height=600,quality=75/HomePage-Banner/Main-Banner/IFB/IFB-Washing-Machine-Now-Available-At-poorvika-01.jpg'
    },
 {
    image: 'https://img.poorvika.com/cdn-cgi/image/width=1900,height=600,quality=75/HomePage-Banner/Main-Banner/Home-Appliances/Home/july/Voltas-AC-Web-Banner.jpg'
 },   
 {
    image: 'https://img.poorvika.com/cdn-cgi/image/width=1900,height=600,quality=75/HomePage-Banner/Main-Banner/Deals/crazy-deals/mobile-appliance-available-at-poorvika-web-banner.jpg'
 },
];

export default SliderData;