import React from 'react';
//import logo from '../../src/Poorvika-english-logo.svg';
import './Header.css';
import Loginpopup from './Loginpopup';
import Logo from './Headersubcomponents/Logo';
import Search from './Headersubcomponents/Search';

function Header () {
  return (
     <div className="header">
      <Logo />
      <Search />


       


      <div className="header-nav-top-const-text header-nav-top-const-locleft">Locate
      <div className="header-nav-top-const-locstore">Store
      <svg xmlns="http://www.w3.org/2000/svg" className="header-nav-top-const-locsvn" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path></svg>
      </div>
      </div>

      <div className="header-nav-top-item">0 items</div>
      <div>
      <button onClick={test} className="header-nav-top-item-button" type="submit">₹ 0</button>
      <div className="header-nav-top-item-svn">
      <svg  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#fff" class="font-extrabold"><path d="M24 3l-.743 2h-1.929l-3.474 12h-13.239l-4.615-11h16.812l-.564 2h-13.24l2.937 7h10.428l3.432-12h4.195zm-15.5 15c-.828 0-1.5.672-1.5 1.5 0 .829.672 1.5 1.5 1.5s1.5-.671 1.5-1.5c0-.828-.672-1.5-1.5-1.5zm6.9-7-1.9 7c-.828 0-1.5.671-1.5 1.5s.672 1.5 1.5 1.5 1.5-.671 1.5-1.5c0-.828-.672-1.5-1.5-1.5z"></path></svg>
      </div>
      </div>

      <div className="header-nav-top-myaccount">My Account</div>
      <div>
      <button onClick={Loginpopup()} className="header-nav-top-account-signin" type="button">Sign In</button>
      </div>
      <div className="header-nav-top-account-signin-svg">
      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="50pt" height="20pt" viewBox="0 0 474.000000 474.000000"
        preserveAspectRatio="xMidYMid meet">
        <g transform="translate(0.000000,474.000000) scale(0.100000,-0.100000)"
        fill="#ffffff" stroke="none">
        <path d="M2093 4675 c-448 -60 -853 -232 -1188 -503 -91 -75 -255 -239 -334
        -336 -471 -576 -638 -1365 -440 -2081 228 -824 880 -1454 1709 -1649 290 -69
        631 -80 919 -30 553 95 1045 380 1404 812 471 567 643 1332 462 2053 -77 305
        -241 636 -434 874 -362 448 -862 744 -1421 842 -181 31 -512 40 -677 18z m582
        -231 c372 -54 724 -208 1021 -448 45 -36 133 -119 195 -184 288 -301 473 -667
        551 -1087 32 -175 32 -536 0 -710 -73 -398 -240 -746 -499 -1040 -28 -30 -44
        -43 -47 -34 -68 227 -116 338 -202 471 -115 179 -280 344 -450 453 -70 45
        -208 118 -254 134 l-35 12 35 27 c266 205 416 468 452 792 30 269 -45 544
        -209 772 -302 419 -872 557 -1339 323 -611 -306 -791 -1117 -369 -1665 53 -68
        166 -177 222 -214 l42 -28 -122 -62 c-148 -74 -237 -133 -343 -225 -201 -174
        -360 -416 -443 -674 -17 -53 -31 -105 -31 -115 -1 -23 -72 54 -161 173 -210
        283 -339 596 -395 955 -21 142 -22 449 -1 592 112 749 603 1374 1297 1653 121
        49 324 105 450 126 191 30 437 32 635 3z"/>
        </g>
        </svg>
      </div>
     </div>
  )   
}

function test () {
  return alert('Item List')
 }
 
export default Header;

