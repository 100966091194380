import React from 'react';
import logo from '../../../src/Poorvika-english-logo.svg';
import '../Header.css';

const Logo = () => {
    return (
        <div>
        <img className="header-img" src= {logo} alt="Poorvika" />
        </div>
    )
}
export default Logo;