import React from 'react';
import './Menu.css';

const submenu = [
'IMac',
'Mini Pcs',
'Charge & Laptop',
];
const submenuitems = submenu.map((submenuitem) =>
<li className="nav__submenu-item">{submenuitem}</li>
);

class Submenu extends React.Component {
    render() {
      return (
        <ul className="nav__submenu">
          {submenuitems}
        </ul>
      )
    }
  }
  export default Submenu;